<template>
  <MutateCalendarMolecule
    :title="$t(`titles.editCalendar`)"
    :calendar="calendar"
    :availableOwners="availableOwners"
    @cancel="$emit('close')"
    @confirm="updateCalendar"
    @find-owners="findOwners"
  />
</template>

<script>
import { nanoid } from 'nanoid'
import { debounce } from 'vue-debounce'
import MutateCalendarMolecule from '../Molecules/MutateCalendarMolecule.vue'

import MY_ORGANISATIONS_SEARCH_QUERY from '#/graphql/organizations/myOrganizations.gql'
import MY_CALENDARS_LIST_QUERY from '#/graphql/calendar/myCalendarsWithMinimalSchedules.gql'
import UPDATE_CALENDAR_MUTATION from '#/graphql/calendar/updateMyCalendar.gql'
import CALENDAR_QUERY from '#/graphql/calendar/show.gql'

export default {
  props: {
    calendarId: {
      type: String,
      required: true,
    },
  },
  components: {
    MutateCalendarMolecule,
  },
  data() {
    return {
      availableOwners: [],
    }
  },
  methods: {
    async debouncedfindOwners(search) {
      this.searchQuery = search
      const results = await this.$apollo.query({
        query: MY_ORGANISATIONS_SEARCH_QUERY,
        variables: {
          search: this.searchQuery,
        },
      })
      const data = results?.data
      if (!data) {
        return
      }
      this.availableOwners = data?.myOrganizations || []
    },
    async updateCalendar({ name, selectedOwnerId, selectedOwnerType }) {
      this.isCreating = true
      let ownerType = null
      if (selectedOwnerType.id === 'Self') {
        ownerType = 'User'
      } else {
        ownerType = selectedOwnerType
      }

      try {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_CALENDAR_MUTATION,
          variables: {
            data: {
              ownerType: {
                set: ownerType,
              },
              ownerId: {
                set: selectedOwnerId,
              },
              name: {
                set: name,
              },
            },
            where: {
              id: this.calendarId,
            },
          },
          update: async (store, { data }) => {
            const newCalendar = data.updateCalendar
            const { myCalendars } = store.readQuery({
              query: MY_CALENDARS_LIST_QUERY,
            })
            const index = myCalendars.findIndex(c => c.id === this.calendarId)
            myCalendars[index] = newCalendar
            store.writeQuery({
              query: MY_CALENDARS_LIST_QUERY,
              data: {
                myCalendars,
              },
            })
          },
        })
        const calendar = res?.data?.updateCalendar
        this.isCreating = false
        if (calendar?.id) {
          this.$emit('updated', res)
          this.$emit('close')
        }
      } catch (err) {
        this.errors.push({
          id: nanoid(),
          rawError: err,
        })
        this.isCreating = false
      }
    },
  },
  created() {
    this.findOwners = debounce(search => {
      this.debouncedfindOwners(search)
    }, 150)
    this.findOwners()
  },
  apollo: {
    calendar: {
      query: CALENDAR_QUERY,
      variables() {
        return {
          where: {
            id: this.calendarId,
          },
        }
      },
      skip() {
        return !this.calendarId
      },
    },
  },
}
</script>
