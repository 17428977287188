var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EditCalendarOverlayStyled', [_c('EditCalendarOrganism', {
    attrs: {
      "calendarId": _vm.args.calendarId
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "updated": _vm.handleUpdated
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }