<template>
  <EditCalendarOverlayStyled>
    <EditCalendarOrganism @close="$emit('close')" :calendarId="args.calendarId" @updated="handleUpdated" />
  </EditCalendarOverlayStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import EditCalendarOrganism from '@/pages/PlanningApp/Organisms/EditCalendarOrganism.vue'

const EditCalendarOverlayStyled = styled('div')`
  width: 100%;
  height: calc(100% - 50px - 1rem);
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;
  .modal {
    height: 100%;
  }
`

export default {
  components: {
    EditCalendarOverlayStyled,
    EditCalendarOrganism,
  },
  props: {
    args: {
      type: Object,
    },
  },
  methods: {
    handleUpdated(payload) {
      const calendarId = payload?.data?.updateCalendar?.id
      this.args?.updatedCallback?.({ calendarId })
    },
  },
}
</script>
